<template>
  <div class="common-table">
    <el-table
      :data="tableData"
      border
      stripe
      show-summary
      highlight-current-row
      :summary-method="getSum"
      :header-row-style="TableHeaderRowStyle"
      :header-cell-style="TableHeaderCellStyle"
      :row-style="TableRowStyle"
      :cell-style="TableCellStyle"
    >
      <el-table-column align="center" prop="collection_date" label="回款时间" width="95" sortable> </el-table-column>
      <el-table-column align="center" prop="collection_amount" label="回款金额" width="95"> </el-table-column>
      <el-table-column align="center" prop="collection_way" label="回款方式" width="190"> </el-table-column>
      <el-table-column align="center" prop="cailiao" label="材料名称" width="100"> </el-table-column>
      <el-table-column align="center" prop="cailiaoguige" label="材料规格" width="100"> </el-table-column>
      <el-table-column align="center" prop="danhao" label="单号" width="100"> </el-table-column>
      <el-table-column align="center" prop="htnamount" label="合同内数量" width="100"> </el-table-column>
      <el-table-column align="center" prop="zlamount" label="增量数量" width="80"> </el-table-column>
      <el-table-column align="center" prop="zujin" label="租金" width="80"> </el-table-column>
      <el-table-column align="center" prop="yajin" label="押金" width="80"> </el-table-column>
      <el-table-column align="center" prop="diaozhuangfei" label="吊装费" width="80"> </el-table-column>
      <el-table-column align="center" prop="yunfei" label="运费" width="80"> </el-table-column>
      <el-table-column align="center" prop="remark" label="备注" width="200"> </el-table-column>
      <el-table-column align="center" prop="added" label="添加" width="130"> </el-table-column>
      <el-table-column align="center" label="管理">
        <template slot-scope="scope">
          <el-button size="mini" @click="handleEdit(scope.row)" v-if="userType.financial_collection_edit">编辑</el-button>
          <el-button size="mini" type="danger" @click="handleDelete(scope.row)" v-if="canDelete(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import utils from '../../assets/js/utils'

export default {
  data() {
    return {}
  },
  props: {
    tableData: Array,
    config: Object,
    userType: Object
  },
  computed: {
    canDelete() {
      return function(row) {
        if (this.userType.financial_collection_del) {
          if (this.userType.id === 1) return true
          else {
            let added_person_time = utils.stringToDate(row.added.split('\n')[1])
            let now = new Date()
            let interval = now.getTime() - added_person_time.getTime()
            interval = interval / 1000 / 60 / 60
            console.log('addedtime:', added_person_time)
            console.log('now:', now)
            console.log('del..........', interval)
            if (interval > 2) return false
            else return true
          }
        } else return false
      }
    }
  },
  methods: {
    TableHeaderRowStyle() {
      return 'height:35px'
    },
    TableHeaderCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;font-weight:700;color:#000;'
    },
    TableRowStyle() {
      return 'height:35px'
    },
    TableCellStyle() {
      return 'padding-bottom:4px;padding-top:4px;font-size:12px;color:#000;'
    },
    //编辑
    handleEdit(row) {
      this.$emit('edit', row)
    },

    //删除
    handleDelete(row) {
      this.$emit('del', row)
    },

    //设置特殊行（如已结单）的状态
    // tableRowClassName(row) {
    //   console.log('row......:', row)
    //   let rowIndex = row.rowIndex
    //   console.log('rowindex:', rowIndex)
    //   if (rowIndex === 0) {
    //     return 'warning-row'
    //   } else if (rowIndex === 1) {
    //     return 'success-row'
    //   }
    //   return ''
    // },
    //合计
    getSum(param) {
      const { columns, data } = param
      const sums = []
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = '合计'
          return
        }
        const values = data.map(item => Number(item[column.property]))
        // column.property === 'bmoney'  bmoney 是你要总计table中的那一列的 prop值
        if (column.property === 'collection_amount') {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr)
            if (!isNaN(value)) {
              return prev + curr
            } else {
              return prev
            }
          }, 0)
          // 如果要格式化 或者处理数据  比方说加千位符,默认保留两位小数等等  直接处理sums[index]就可以
          sums[index] = sums[index].toFixed(2)
        } else {
          sums[index] = '--'
        }
      })
      return sums
    }
  }
}
</script>
